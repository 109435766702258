:root {
  --light-bg: #F9F0C6;
  --light-border: #ffffff;
  --light-slidebar-bg: #F7F7F7;
  --light-slidebar-btn: #333333;
  --light-data-text: #707070;
  --blue-transparency-bg: rgba(0,0,0,0.6);
}

body  {
  font-family: 'Poppins', sans-serif;
}

/* Remove padding from top menu */

body.navbar-is-fixed-top {
  padding-top:0px;
}

h4 {
  padding:25px 0;
}

body.front {
  p {
    word-wrap: break-word;
  }
  .views-more-link {
    font-weight: bold;
  }
  .region-ab-c-fluid {
    .slick__slide img {
      object-fit: cover;
      @media #{$tablet-max} {
        object-fit: cover;
      }
    }
    .slick .fp_caption {
      font-size:1.5em;
      color:#FFF;
    }
    @media #{$tablet} {
      .slick .fp_caption {
        font-size:2em;
      }
      .slick-active .fp_caption {
        top: 38%;
      }
    }
    @media #{$normal} {
      .slick .fp_caption {
        font-size:3em;
      }
      .slick-active .fp_caption {
        top: 25%;
      }
    }
    .slick-active .fp_caption {
      background-color: rgba(0, 0, 0, 0.5);
      left: 0;
      padding: 20px 0;
      position: absolute;
      text-align: center;
      top: 35%;
      width: 100%;
      z-index: 100;
      .field-content {
        opacity:1;
        overflow: hidden;
      }
    }
  }
}

body.not-front {
  &.admin-menu.navbar-is-fixed-top {
    padding-top:1px !important;
  }
  #content_area_frame {

  }
  .gap {
    margin-top:50px;
  }
}

section.abovefooter {
  clear:both;
  float:none;
  border-top:1px solid #ebebeb;
  background-color:#fafafa;
  padding-bottom:20px;
  padding-top:20px;
  margin-top:20px;
  .view-id-fleet .views-fleet-title {
    margin-bottom:5px;
  }
  a:link,
    a:visited {
    color:#000;
  }
  a:hover {
    color:#FFA500;
  }
}

header#navbar {
  -webkit-box-shadow: 0 8px 3px -7px #999;
  -moz-box-shadow: 0 8px 3px -7px #999;
  box-shadow: 0 8px 3px -7px #999;
  &.affix-top {
    a.logo img {
      width:300px;
    }
  }
  &.affix {
    width:50px;
  }

}

header#navbar.navbar-fixed-top {
  background-color: rgba(255, 255, 255, 0.9);
  padding-top:0px;
  -webkit-transition: all 0.3s linear;
  -moz-transition:    all 0.3s linear;
  -o-transition:      all 0.3s linear;
  transition:         all 0.3s linear;
  a.logo {
    margin:0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .site-name {
      margin-left:5px;
      margin-top:20px;
      h4 {
        margin:0px;
        padding:0px;
        color:#555;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  a.logo img {
    display:block;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .region-navigation {
    ul li {
      float:left;
      @media screen and (max-width: 767px) {
        float:none;
      }
      a {
        margin: 1em 0;
        padding:5px 15px;
        transition: background-color 0.5s ease;
        border-radius: 5px;
        &:hover {
          background-color: #FFBC00;
        }
      }
    }
  }
  #block-menu-menu-visitors {
    h2 {
      display:none;
    }
  }
}
/* Form modifications */

.node-webform {
  .form-control {
    height:50px;
    @media #{$mobile} {
      height:50px;
    }
    @media #{$tablet} {
      height:34px;
    }
  }
}


.node-webform {
  .form-item {
    .year,
    .month,
    .day {
      width:100%;
      float:none;
      @media #{$tablet} {
        width:33%;
        float:left;
      }
    }
  }
}


/* Special hacks */
.region-navigation {
  ul.menu {
    li.fitsize {
      /*font-size:75%;*/
    }
  }
}

.hideme{
  opacity:0;
}
.visible{
  opacity:1;
}


/* Video */
.videoheader {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.videoheader video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Various */

.ab_c_high {
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
}

h4.dbb_breadcrumb {
  padding:0px;
  margin-top:0px;
}


/* Webform textarea */

.webform-component-textarea {
  .form-textarea-wrapper {
    textarea {
      height: 180px;
      resize: none;
    }
  }
}

/* Mods */
.pager li > a,
.pager li > span {
  border-radius: 4px;
}


#block-views-lt-latest-entries-fp-block,
#block-views-lt-trip-list-active-fp-block,
#block-views-lt-trip-list-future-fp-block
{
  h2 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include mq($from: mobile, $until: tablet) {
      font-size:20px;
    }
  }
}

/* Frontpage: latest entries  */
.view-lt-latest-entries-fp {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    > [class*='col-'] {
      display: flex;
      flex-direction: column;
    }
  }

  .views-row-enclosure {
    margin-bottom:30px;
    border:1px solid #DDD;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #FFF;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
    a {
      text-decoration: none;
    }
  }
  .views-field-nothing {
    margin-top:-9px;
    position:relative;
    background-color: #FFF;
    .field-content {
      position:absolute;
      right:10px;
      top:-17px;
      padding: 10px;
      border-radius: 4px;
      background-color: #FFBC00;
      a {
        color: #333;
      }
    }
  }
  .views-field-field-e-img {
    .field-content {
      a img {
        margin:0 auto;
      }
    }
  }
  .views-field-body {
    min-height:100px;
    clear:both;
    margin-bottom:10px;
    padding:0 18px;
    a:link,
    a:visited {
      color:#555;
    }
    /* Set min-height to 608px */
    .field-content {
      margin-top:15px;
      line-height: 1.45;
      position: relative;
      color: #555;
    }
  }
  .views-field-field-e-datetime-sent,
  .views-field-field-trip-transporation {
    float:left;
    color:#8F8F8F;
    padding-left:18px;
    @include mq($from: mobile, $until: tablet) {
      margin-top:20px;
    }
  }
  .views-field-field-e-country {
    float:left;
    padding-bottom:20px;
    &:before {
      content: "- ";
      margin-left:5px;
    }
    @include mq($from: mobile, $until: tablet) {
      margin-top:18px;
    }
  }
  .views-field-field-u-avatar {
    float:left;
    clear:both;
    padding: 35px 0 20px 18px;
    .img-responsive {
      border-radius: 4px;
    }
  }
  .views-field-name,
  .views-field-travellername-link {
    float:left;
    padding: 48px 0px 15px 20px;
    a:link,
    a:visited {
      color:#555;
      font-weight: bold;
    }
  }
  .views-field-title {
    position:absolute;
    width:auto;
    left:0;
    right:0;
    margin:0 auto;
    text-align: center;
    background-color:rgba(85, 85, 85, 0.7);
    margin-left:16px;
    margin-right:16px;
    padding: 10px 0;
    a {
      font-size:1.2em;
      color: white;
      font-weight: bold;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.3em;
      }
    }
  }

  // Customize the 'Load More' pager
  .pager {
    li {
      a {
        background-color:#666;
        color:#fcd708;
        font-weight:bold;
      }
      a:hover {
        color: #FFF;
      }
    }
  }
}

/* Frontpage: active trips  */
.view-lt-trip-list-active-fp {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    > [class*='col-'] {
      display: flex;
      flex-direction: column;
    }
  }

  .views-row-enclosure {
    margin-bottom:30px;
    border:1px solid #DDD;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #FFF;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
    a {
      text-decoration: none;
    }
  }
  .views-field-nothing {
    position:relative;
    background-color: #FFF;
  }
  .views-field-field-e-img {
    .field-content {
      a img {
        margin:0 auto;
      }
    }
  }
  .views-field-body {
    min-height:100px;
    clear:both;
    margin-bottom:10px;
    padding:0 18px;
    a:link,
    a:visited {
      color:#555;
    }
    /* Set min-height to 608px */
    .field-content {
      margin-top:15px;
      line-height: 1.45;
      position: relative;
      color: #555;
    }
  }
  .views-field-nothing,
  .views-field-field-trip-transporation {
    padding-left:18px;
    @include mq($from: mobile, $until: tablet) {
      margin-top:20px;
    }
  }
  .views-field-nothing {
    padding-bottom: 20px;
  }
  .views-field-field-e-country {
    float:left;
    padding-bottom:20px;
    &:before {
      content: "- ";
      margin-left:5px;
    }
    @include mq($from: mobile, $until: tablet) {
      margin-top:18px;
    }
  }
  .views-field-field-u-avatar {
    .field-content {
      a img {
        margin:0 auto;
      }
    }
  }
  .views-field-name,
  .views-field-travellername-link {
    float:left;
    padding: 48px 0px 15px 20px;
    a:link,
    a:visited {
      color:#555;
      font-weight: bold;
    }
  }
  .views-field-title {
    position:absolute;
    width:auto;
    left:0;
    right:0;
    margin:0 auto;
    text-align: center;
    background-color:rgba(85, 85, 85, 0.7);
    margin-left:16px;
    margin-right:16px;
    padding: 10px 0;
    a {
      font-size:1.2em;
      color: white;
      font-weight: bold;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.3em;
      }
    }
  }

  // Customize the 'Load More' pager
  .pager {
    li {
      a {
        background-color:#666;
        color:#fcd708;
        font-weight:bold;
      }
      a:hover {
        color: #FFF;
      }
    }
  }
}


.view-lt-trip-list-active-fp {
  .views-field-field-u-avatar {
    float:none;
    clear:both;
    padding: 0;
    .img-responsive {
      border-radius: 4px;
    }

    .field-content {
      a img {
        margin:0 auto;
      }
    }
  }
}

/* Block decoration */

#map_canvas {
  width:100%;
  height:300px;
  box-shadow: 0px 5px 8px 0px #999999;
  @media only screen and (min-width: 481px) {
    height:600px;
  }
}

#block-lt-custom-app-left {
  transition: 1.5s; /* THE DURATION */
}

/* Elements */

.hideme {
  opacity:0;
}

.showme {
  opacity:1;
}

/* Trip page*/
.grid .view-content  {
  margin: 1.5em 0;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;

  @media only screen and (min-width: 500px) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  @media only screen and (min-width: 700px) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

  @media only screen and (min-width: 900px) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

  @media only screen and (min-width: 1100px) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;

  }
}

.item {
  display: inline-block;
  background: #fff;
  margin: 0 0 1.5em;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0,1,1,.2);
  box-shadow: 0 3px 5px 0 rgba(0,1,1,.2);
  img {
    width: 100%;
  }
  ul {
    padding-left: 0;
    list-style: none;
  }
  .info{
    padding: 0px 20px 0px;
  }
  .body {
    padding: 10px 0;
  }
}


.grid .view-filters {
  margin-bottom: 50px;
  #edit-uid-wrapper {
    width:100%;
    @media only screen and (min-width: 700px) {
      width: 40%;
    }

  }

  #edit-field-t-date-start-value-wrapper div.date-year select{
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  @media only screen and (max-width: 450px) {
    .views-exposed-widget {
      float: none;
      button {
        width: 100%;
        margin-top: 1em;
      }
    }
    #edit-field-t-date-start-value-wrapper,
    #edit-field-trip-transporation-tid-wrapper{
      width: 100%;
      float: none;
      .date-padding {
        float: none;
      }
    }
  }
}

.btn-info {
  background-color: #FFBC00;
  color: #555555;
  border-radius: 0;
  border: none;
  padding: 8px 20px;
  &:hover {
    color:#fff;
    background-color: #555555;
  };
}
/* End of trip page */

/* User page */

fieldset.group-us-addinfo {
  color:black;
}

.page-user-your-data {
  .gdpr_alldata_btn {
    height:45px;
    margin-bottom:10px;
  }
  .gdpr_alldata_dl {
    text-align:center;
  }
}

/* End of user page */


/* Scroll to top */

.scrolltop {
  display:none;
  width:100%;
  margin:0 auto;
  position:fixed;
  bottom:20px;
  right:10px;
}
.scroll {
  position:absolute;
  right:20px;
  bottom:20px;
  background:#FFBC00;
  background:rgba(255, 188, 0, 0.5);
  padding:20px;
  text-align: center;
  margin: 0 0 0 0;
  cursor:pointer;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.scroll:hover {
  background:rgba(255, 188, 0, 1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.scroll:hover .fa {
  padding-top:-10px;
}
.scroll .fa {
  font-size:30px;
  margin-top:-5px;
  margin-left:1px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
/* End of scroll to top */
