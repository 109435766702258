body.page-location-entries {
  font-family: 'Poppins', sans-serif;
  .main-container {
    margin-top:6em;
  }
  .view-lt-entry-visuals {
    &.view-display-id-blk_addtldata {
      .views-field {
        padding-bottom: 1em;
      }
      .views-field-body {
        word-wrap: break-word;
      }
    }
    &.view-display-id-blk_img img {
      border-radius: 20px;
    }

  }
}
