body.front {
  #block-views-lt-frontpage-slideshow-block {
    .views-field.views-field-field-fp-image {
      img {
        margin: 0 auto;
        width: 100%;
        height: 450px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: FontAwesome;
    font-size: 58px;
    line-height: 1;
    color: #fff000;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev:before {
    content: "\f053";
  }
  [dir="rtl"] .slick-prev:before {
    content: "\f054";
  }
  [dir="rtl"] .slick-next {
    left: -10px;
    top: 70px;
    right: auto;
  }
  .slick-next:before {
    content: "\f054";
  }
  [dir="rtl"] .slick-next:before {
    content: "\f053";
  }

}