body.node-type-trip {
  font-family: 'Poppins', sans-serif;
  height:100vh;
  background-color: var(--light-bg);
  // Scrollbar fix for all browsers
  #js-slider::-webkit-scrollbar {
    width: 5px;
  }

  #js-slider::-webkit-scrollbar-track {
    background-color: darkgrey;
  }

  #js-slider::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.8);
  }

  #js-slider::-webkit-scrollbar-button {
    background-color: darkgrey;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 16px;
    width: 1em;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
  }

  #js-slider::-webkit-scrollbar-button:end:increment {
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
  }

  #js-slider::-webkit-scrollbar-button:start:decrement {
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==)
  }

  .views-row {
    .entry_separator {
      position: relative;
      padding: 28px;
      background: #f3f5f7;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center;
      &:before,
      &:after {
        left: 50%;
        top: 28px;
        position: absolute;
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        border: 0;
        border-left-color: currentcolor;
        border-left-style: none;
        border-left-width: 0px;
        border-left: 1.5px solid #b7c0cd;
        width: 1.5px;
    }
    .decor {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: var(--light-slidebar-bg);
        border: 1.5px solid #b7c0cd;
        position: relative;
        z-index: 2;
      }
    }
  }
  .views-row-last {
    .entry_separator,
    .decor {
      display: none;
    }
  }
}

.node-type-trip {

  #block-lt-custom-trip-map {
    width: 100%;
    position: relative;
  }

  #road-map,
  #tripmap_canvas {
    position: relative;
    width:100%;
    height:100vh;
    padding: 0px;
    margin-left: 0;
  }
  #roadmapcontainer {
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .main-container.container-fluid {
    margin-top:0px;
  }
  .view-lt-trip-infosheet {
    &.view-display-id-block {
      .views-field.views-field-name-1 {
        background-color: #379ac9;
        padding: 10px;
        color: white;
        a:link,
        a:visited {
          color: white;
        }
        a:hover {
          color:#000;
        }
      }
    }
    /* Count of messages */
    &.view-display-id-attach_entries {
      .views-row {
        font-size:2em;
        span {
          margin:2px;
          border-radius: 20px;
          background-color: #666;
          padding:5px;
          color:white;
        }
      }
    }
    &.view-display-id-attach_countries {
      .views-rows {
        font-size:0.9em;
      }
    }
  }
  #block-views-lt-trip-entries-page-ajxblock,
  #block-views-lt-trip-infosheet-avatar {
    h2.block-title {
      position: relative;
      overflow: hidden;
      text-align: center;
      margin-top:0px;
      &:before,
      &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '\a0';
        background-color: #666;
      }
      &:before {
        margin-left: -50%;
        text-align: right;
      }
    }
  }
  .view-id-lt_trip_entries,
  .view-lt-trip-entries-page {
      .views-row-enclosure {
        margin-bottom:20px;
        min-height: 570px;
        min-height: 608px;
        padding:5px;
        @media #{$mobile-max} {
          margin-bottom:0px;
        }
      }
      .views-field-nothing {
        padding: 20px 20px;
        border-bottom:1px solid #999;
          a {
            padding:10px;
            background-color: #666;
            color:white;
          }
      }
      .views-field-field-e-img {
        margin-top:20px;
          .field-content {
              a img {
                margin:0 auto;
                border:1px solid black;
              }
          }
      }
      .views-field-body {
        padding: 0;
          a:link,
          a:visited {
            color:black;
          }
          /* Activate for Blockquotes  */
          /* Set min-height to 608px */
          .field-content {
            font-style: italic;
            margin-top:20px;
            line-height: 1.45;
            position: relative;
            color: #383838;
            .entry.readmore,
            .entry.readless {
              text-align: center;
              padding:5px;
              background-color: #333;
              color:#000;
              width:50%;
              border-radius:20px;
              margin:10px auto 0 auto;
              .accordion-group {
                padding-bottom:1.8em;
              }

            }
            .entry.readmore:before {
              font-family: FontAwesome;
              content: "\f103";
              display: inline-block;
              padding-right:20px;
            }
            .entry.readmore:after {
              font-family: FontAwesome;
              content: "\f103";
              display: inline-block;
              padding-left:20px;
            }
            .entry.readless:before {
              font-family: FontAwesome;
              content: "\f102";
              display: inline-block;
              padding-right:20px;
            }
            .entry.readless:after {
              font-family: FontAwesome;
              content: "\f102";
              display: inline-block;
              padding-left:20px;
            }
          }
      }
      .views-field-title,
      .views-field-field-u-avatar {
        padding:20px;
        img {
          margin: 0 auto;
        }
      }
      .social_links {
        padding-top:5px;
        padding-bottom:5px;
        div {
          float:left;
          padding:5px !important;
        }
      }
  }
  .view-lt-trip-entries-page  {
    .views-row-enclosure {
      min-height:400px;
      @media #{$mobile-max} {
        min-height:280px;
      }
    }
    .views-field-field-e-img {
      margin-top:20px;
    }
  }
}


/* Sidebar modifications */
body {
  &.admin-menu {
    #sidebar {
      top:96px;
    }
  }
  .leaflet-bottom.leaflet-right {
    z-index:500;
  }
  #sidebar {
    position:fixed;
    top:66px;
    .leaflet-sidebar-header {
      text-align:left;
      background-color: #FFF;
      color:#000;
    }
    .leaflet-sidebar-pane {
      padding-left: 5px;
    }
    .leaflet-sidebar-tabs {
      background-color: #141414;
      li.active {
        background-color: #8F8F8F;
      }
      li {
        a {
          color:#FFF;
          &:hover {
            color:#141414;
          }
        }
        @media #{$mobile-max} {
          font-size:18pt;
        }
      }
    }
    .leaflet-sidebar-close {
      background-color: #FFF;
      i {
        color:#000;
      }
      @media #{$mobile-max} {
        font-size:18pt;
      }
    }
    .leaflet-sidebar-content {
      background-color: rgba(255,255,255,0.75);
    }
    #block-views-lt-trip-entries-page-ajxblock {
      text-align:left;
      font-size:1.2em;
      .view-lt-trip-entries-page {
        .views-field-body {
          color:#FFF;
        }
      }
    }
    #block-views-trip-info-trip-countries,
    #block-views-trip-info-main {
      text-align: left;
      font-size:1.2em;
      h2.block-title {
        position: relative;
        overflow: hidden;
        text-align: center;
        @media #{$mobile-max} {
          font-size:1.5em;
        }
        &:before,
        &:after {
          position: absolute;
          top: 51%;
          overflow: hidden;
          width: 50%;
          height: 1px;
          content: '\a0';
          background-color: #666;
        }
        &:before {
          margin-left: -50%;
          text-align: right;
        }
      }
    }
    #block-views-trip-info-main {
      h2.title {
        @media #{$mobile-max} {
          font-size:1.5em;
        }
      }
      .views-field-body {
        margin-top:20px;
        margin-bottom:20px;
      }
      .attachment .view-trip-info {
        h3 {
          display: none;
        }
      }
    }
  }

  .row.cnt {
    display:flex;
    flex-wrap: wrap;
  }

  // Hamburger icon
  #js-hamburger {
    &.collapsed {
      width:0.5%;
      &::after {
        margin-left:-4px;
        @media #{$mobile-max} {
          margin-left:0;
        }
      }
    }
    cursor: pointer;
    width:0.5%;
    padding-top: 50vh;
    background-color:#FFFFFF;
    z-index: 401;
    @media #{$tablet-normal} {
      width: 0.5%;
    }
    @media #{$mobile-max} {
      margin-top:0px;
      text-align:center;
      background: rgba(0, 0, 0, 0.5);
    }
    &::after {
      content: "\f053";
      font-family: "FontAwesome";
      font-size: 1.1em;
      -moz-box-shadow: 0px 5px 6px rgba(0,0,0,.4); /* FF3.5+ */
      -webkit-box-shadow: 0px 5px 6px rgba(0,0,0,.4); /* Saf3.0+, Chrome */
      box-shadow: 0px 5px 6px rgba(0,0,0,.4); /* Opera 10.5, IE 9.0 */
      border-radius:5px;
      padding: 1em 5px 1em 5px;
      background-color: #FFFFFF;
      @media #{$mobile-max} {
        content: "\f054";
        content: "\f0ac";
        padding: 0.8em 0.5em 0.8em 0.5em;
        background: rgba(0, 0, 0, 0.5);
        color: #FFF;
        font-size: 1.8em;
        border-radius:5px 0 0 5px;
      }
    }
    &.collapsed {
      &::after {
        content: "\f054";
        font-family: "FontAwesome";
        font-size: 1.1em;
        @media #{$mobile-max} {
          content: "\f053";
          content: "\f0ac";
          padding: 0.8em 0.5em 0.8em 0.5em;
          background: rgba(0, 0, 0, 0.5);
          color: #FFF;
          font-size: 1.8em;
          border-radius:0 5px 5px 0;
        }
      }
    }
  }

  #js-slider {
    transition: all 0.3s ease-in-out;
    float:left;
    overflow: scroll;
    height: 100vh;
    background-color: var(--light-slidebar-bg);
    .navtab-header {
      width:22vw;
      margin:0 1vw;
      padding:1em 0;
      background-color: var(--light-bg);
      border-radius: 20px 20px 0 0;
      position: fixed;
      top: 4em;
      z-index: 101;
      height: auto;
      @media #{$tablet-normal} {
        width: 48vw;
        padding:0.5em 0;
        top: 4.4em;
      }
      @media #{$mobile-max} {
        border-radius: 0;
        margin: 0;
        width:100vw;
        padding:0.5em 0;
        top: 4.4em;
      }
    }
    .logo {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24vw;
      margin: 0;
      background-color: var(--light-slidebar-bg);
      position: fixed;
      top: 0;
      z-index: 102;
      height: auto;
      @media #{$mobile-max} {
        width: 100vw;
        height: 62px;
        padding: 0;
      }
      @media #{$tablet-normal} {
        width: 48vw;
        padding: 0;
      }
      h4 {
        margin: 0px;
        padding: 0px;
        color: #555;
        text-decoration: none !important;
      }
    }
    ul {
      background-color: var(--light-border);
    }
    .nav-tabs {
      border-bottom: none;
      width:97%;
      margin: 0 auto;
      border-radius: 20px;
    }
    .navitem {
      padding: 10px;
      width: 33%;
      a {
        border-radius: 20px;
        padding: 10px 30px;
        background-color: var(--light-border);
        text-align:center;
      }
      @media #{$mobile-max} {
        padding: 6px;
      }
    }
    .navitem.active {

      a {
        border:none;
        background-color: var(--light-bg);
      }
    }
    #block-views-trip-info-trip-countries {
      width: 94%;
      margin:0 auto;
      .view-content {
        margin-top: 17em;
        .views-field-field-e-country {
          margin: 0.35em 0;
          padding: 0 6px;
          span {
            padding: 10px;
            border-radius: 10px;
            background-color: #FFFFFF;
            display:flex;
            justify-content: space-between;
          }
        }
      }
      .view-footer {
        float: none;
        clear: both;
        margin: 0.35em 0;
        padding: 0 7px;
        .ftr {
          padding: 10px;
          border-radius: 10px;
          background-color: #FFFFFF;
          display:flex;
          justify-content: space-between;

        }
      }
    }
    #block-views-trip-info-main {
      width: 94%;
      margin:0 auto;
      margin-top: 3em;
      .view-content {
        margin-top: 15em;
        padding: 0 1em;
        .views-row {
          padding: 0px;
          .views-field-nothing-1 {
            padding: 0.8em 0.8em 0.8em 0;
            span {
              background-color:#FFFFFF;
              border-radius: 10px;
              color: var(--light-data-text);
              padding:10px;
            }
          }
          .views-field-body {
            margin: 1.5em 0;
          }
          .views-field-field-u-avatar {
            margin-bottom:1em;
          }
          .views-field-title {
            font-size:1.3em;
          }
          .views-field-field-trip-transporation,
          .views-field-nothing,
          .views-field-field-t-status {
            padding: 0.8em;
            background-color:#FFFFFF;
            border-radius: 10px;
            color: var(--light-data-text);
            width: fit-content;
            margin: 0.5em 0;
            span.field-content {
              font-weight: bold;
            }
          }
          .views-field-field-trip-transporation {
            &:before {
              content: " ";
              background-image: url("../img/elements/vehicletype.svg");
              background-size: contain;
              background-size: 15px 15px;
              width: 15px;
              height: 15px;
              display: inline-block;
              vertical-align:middle;
              margin-right:0.5em;
              margin-bottom:5px;
            }
          }
          .views-field-nothing {
            &:before {
              content: " ";
              background-image: url("../img/elements/clock.svg");
              background-size: contain;
              background-size: 15px 15px;
              width: 15px;
              height: 15px;
              display: inline-block;
              vertical-align:middle;
              margin-right:0.5em;
              margin-bottom:5px;
            }
          }
          .views-field-field-t-status {
            &:before {
              content: " ";
              background-image: url("../img/elements/tripstatus.svg");
              background-size: contain;
              background-size: 15px 15px;
              width: 15px;
              height: 15px;
              display: inline-block;
              vertical-align:middle;
              margin-right:0.5em;
              margin-bottom:5px;
            }
          }
          img {
            border-radius: 10px;
          }
          .views-field-field-u-avatar {
            img {
              margin: 0 auto;
              width: 75%;
            }
          }


        }
      }
      .view-display-id-attachment_2 {
        h3 {
          display: none;
        }
        // Message counter
        .views-row {
          padding: 0.8em;
          background-color:#FFFFFF;
          border-radius: 10px;
          color: var(--light-data-text);
          width: fit-content;
          margin: 0.5em 0;
          &:before {
            content: " ";
            background-image: url("../img/elements/msgcount.svg");
            background-size: contain;
            background-size: 15px 15px;
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align:middle;
            margin-right:0.5em;
            margin-bottom:5px;
          }
        }
      }
      .attachment.attachment-after {
        .view-content {
          margin-top: 0;
        }
      }
    }
    .region.region-slidingbar-first {
      margin-top: 19em;
      @media #{$mobile-max} {
        margin-top: 15em;
      }
      @media #{$tablet-normal} {
        margin-top:  16em;
      }
    }
    .infoheader {
      width: 22vw;
      position:fixed;
      top: 11em;
      background-color: var(--light-bg);
      margin:0 1vw;
      margin-bottom:1em;
      border-radius: 0 0 20px 20px;
      z-index:100;
      @media #{$tablet-normal} {
        width: 48vw;
        top: 10.5em;
      }
      @media #{$mobile-max} {
        width: 100vw;
        margin: 0;
        top: 9em;
      }
      h2.page-title-header {
        margin-top: 0px;
        padding: 0.2em 0.9em 0em 0.9em;
        text-align: center;
        font-size:1.6em;
        min-height: 3em;
        &.center {
          padding: 0.2em 0.2em 0.2em 0.2em;
        }
      }

      .region.region-slidingbar-ihdr {
        position: relative;
        #block-views-trip-info-trip-status {
          position: absolute;
          bottom: 0.1em;
          right: 1.0em;
        }
      }
    }
    .view-lt-trip-entries-ajax {
      .views-row {
        padding:0 1.4em;
        a {
          color: #000000;
          hover {
            color: var(--light-data-text);
          }
        }
      }

    }
    @media #{$mobile-max} {
      height:auto;
    }
  }

  // The main container
  #js-container {
    transition: all 0.3s ease-in-out;
    width:75.5%;
    height:100vh;
    flex-wrap: nowrap;
    #mobilescroller {
      opacity: 0;
      position: fixed;
      left:0;
      top:90vh;
      width:4em;
      height:4em;
      z-index:450;
    }
    @media #{$mobile-max} {
      width:100%;
      #mobilescroller {
        opacity: 0;
        position: fixed;
        left:0;
        top:50vh;
        width:3em;
        height:3em;
        z-index:450;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 0 10px 10px 0;
        cursor: pointer;
        .fa {
          padding-left: 0.15em;
        }
      }
    }
    @media #{$tablet-normal} {
      width:49.5%;
    }
    &.active {
      width:99.5%;
    }
    header.navbar.navbar-fixed-top  {
      position: relative;
      right: auto;
      left: auto;
      top: 0;
      margin-bottom: 0;
      background: inherit;
      box-shadow: none;
      .container {
        width: 50vw;
        @media #{$mobile-max} {
          width:100vw;
        }
      }
    }
  }

  // The slider (sidebar)
  #js-slider {
    transition: all 0.3s ease-in-out;
    width:24%;
    @media #{$mobile-max} {
      width:100%;
      height:auto;
    }
    @media #{$tablet-normal} {
      width:50%;
    }
    &.inactive {
      width: 0;
      padding: 0;
      overflow: hidden;
      .logo {
        display: none;
      }
      .navtab-header {
        position: inherit;
      }
      .infoheader {
        position: inherit;
      }
      @media #{$mobile-max} {
        width:100%;
        height:0;
      }
      @media #{$tablet-normal} {
        width:100%;
        height:0;
      }
    }
  }

  article.node-entry {
    border-radius: 14px;
    background-color: white;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    &:hover {

    }
    .bodysummary {
      word-wrap: break-word;
      .field-name-body {
        padding: 0.7em 0.4em;
      }
    }
    .entrylog {
      position: relative;
      width: 100%;
      display: block;
      margin-bottom: 0;
      // Where to focus when clicking
      &:target {
        display: block;
        position: relative;
        top: -17em;
        visibility: hidden;
        @media #{$tablet-normal} {
          top: -16em;
        }
      }

    }
    .field-type-text-with-summary {
      padding: 10px 0;
    }
    img {
      border-radius:10px;
      width:100%;
      height:100%;
      display:block;
      margin:0 auto;
      @media #{$tablet-normal} {
        width: 100%;
        height: auto;
      }
    }
  }

  .btn.btn-trip-info {
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 10px;
    border: none;
    padding: 8px 20px;

  }

  .social_links {
    padding: 8px 0;
    border-radius:10px;
    > div {
      padding: 10px;
    }
  }

  .entrydata {
    .spacer {
      margin:20px;
    }
    .entry.body {
      padding: 0.7em 0.2em;
      word-wrap: break-word;
    }
    .entry.country,
    .entry.continent {
      padding: 0.8em 0.8em 0.8em 0;
      span {
        background-color:#FFFFFF;
        border-radius: 10px;
        color: var(--light-data-text);
        padding:10px;
        &:before {
          content: " ";
          background-image: url("../img/elements/globe.svg");
          background-size: contain;
          background-size: 15px 15px;
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align:middle;
          margin-right:0.5em;
          margin-bottom:5px;
        }
      }
    }
    .entry.datetime {
      padding: 0.8em 0.8em 0.8em 0;
      span {
        background-color:#FFFFFF;
        border-radius: 10px;
        color: var(--light-data-text);
        padding:10px;
        &:before {
          content: " ";
          background-image: url("../img/elements/clock.svg");
          background-size: contain;
          background-size: 15px 15px;
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align:middle;
          margin-right:0.5em;
          margin-bottom:5px;
        }
      }
    }

    .entry.location {
      padding: 0.8em 0.8em 0.8em 0;
      >span {
        background-color:#FFFFFF;
        border-radius: 10px;
        color: var(--light-data-text);
        padding:10px;
      }
      >:before {
        content: " ";
        background-image: url("../img/elements/coordinates.svg");
        background-size: contain;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align:middle;
        margin-right:0.5em;
        margin-bottom:5px;
      }

    }

    .entry.traveller {
      padding: 0.8em 0.8em 0.8em 0;
      span {
        background-color:#FFFFFF;
        border-radius: 10px;
        color: var(--light-data-text);
        padding:10px;
        &:before {
          content: " ";
          background-image: url("../img/elements/man-user.svg");
          background-size: contain;
          background-size: 18px 18px;
          width: 18px;
          height: 18px;
          display: inline-block;
          vertical-align:middle;
          margin-right:0.5em;
          margin-bottom:5px;
        }
      }
    }
  }
}

// Mobile modifications
@media #{$tablet-normal} {
  body.node-type-trip {
    #tripmap_canvas {

    }
  }
}
@media #{$mobile-max} {
  html.mapvisible {
    body {
      height: 100%;
      overflow-y: hidden;
    }
    body.node-type-trip {
      #js-slider {
        .navtab-header {
          position: inherit;
          display: none;
        }
        .infoheader {
          position: inherit;
          margin: 0 0;
          border-radius: unset;
          width: 100vw;
          h2 {
            padding: 0.3em;
          }
        }
      }
      #js-container {
        top:0;
      }
    }
  }
  body.node-type-trip {
    background-color: var(--light-slidebar-bg);
    #js-container {
      background-color: var(--light-bg);
      display:block;
      position:fixed;
      top:14vw;
      left:-100vw;
      width:100vw;
      height:92.5%;
      &.mapvisible {
        left:0;
        #tripmap_canvas {

        }
      }
    }
    #block-lt-custom-trip-map {
      background-color: var(--light-bg);
      border-radius:20px;
    }
    #tripmap_canvas {
      width:100%;
      padding: 0px;
      margin: 0;
    }
  }

  .row {
    // Hamburger icon on mobile displays
    #js-hamburger {
      background: none;
      transition: all 0.3s ease-in-out;
      padding-top:0px;
      margin-bottom:1.5em;
      position:fixed;
      // Position of the hamburger on mobile
      bottom:0;
      right: 3.5em;
      z-index:10;
      &.collapsed {

      }
    }

    #js-slider {
      height:60%;
      .navtab-header {
        li {
          a {
            img {
              width:1.3em;
            }
          }
        }
      }
      .tab-content {
        h2 {
          font-size:1.8em;
        }
        .infoheader {
          padding-bottom: 2px;
        }
      }
      .view-lt-trip-entries-ajax  {
        .view-content {
            align-items: stretch;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            .views-row {
              padding:0 1%;
              article.node-entry {
                margin-bottom: 2rem;
                border: 0;
                padding: 0;
                padding-bottom: 1em;
                min-height: 515px;
                .field-name-body,
                .entrydata {
                  padding: 0.7em 0.3em;
                }
                img {
                  margin:1vw;
                  margin-bottom: .75rem;
                  width: 96vw;
                  height: auto;
                  border-radius:10px;
                }
                .btn.btn-trip-info {
                  margin-left: 0.3em;
                }
                .entrylog {
                  margin-bottom:0px;
                  height:inherit;
                }
              }
              .entry_separator {
                opacity: 0;
                .decor {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
}
