/* User page - URL : /users/* */
aside.content.bottom.left,
aside.content.bottom.right {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  background-color: rgba(255,255,255,0.85);
}

aside.content.bottom.left {
  padding: 20px;
  padding-left: 0;
  //background: var(--blue-transparency-bg) !important;
  position: relative;
  color: white;
  >div {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  background: url("../img/bg/map01_600.jpg") rgba(0,0,0,.5);
  background-repeat: repeat;
  background-size: 100%;
  background-blend-mode: multiply;
}

// Scrollbar fix for all browsers
.page-user aside.content.bottom.right::-webkit-scrollbar,
.page-user aside.content.bottom.left::-webkit-scrollbar {
  width: 5px;
}

.page-user aside.content.bottom.right::-webkit-scrollbar-track,
.page-user aside.content.bottom.left::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.page-user aside.content.bottom.right::-webkit-scrollbar-thumb,
.page-user aside.content.bottom.left::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.8);
}

// The rest
.view-lt-user-info {
  .view-header {
    font-size: 1.8em;
  }
  @include mq($from: mobile, $until: tablet) {
    margin-top:10px;
  }
  h3 {
    margin-top:0px;
  }
  .views-row {
    .views-field-nothing {
      .tripprivate {
        font-weight: bold;
        color: red;
      }
    }
  }
  .views-field-field-u-avatar {
    img {
      width: 200px;
      margin: 0 auto;
      border-radius: 1.5em;
    }
  }
}

.page-user {
  .navbar {
    margin-bottom: 0;
  }
}

.page-users {
  &body {
    height:100%;
    font-family: 'Poppins', sans-serif;
  }
  height:100%;
  #content_area {
    width:100%;
    height:100%;
    background-color: #D5D5D5;
  }
  footer.footer {
    display:none;
  }
  .main-container {
    margin-top:60px;
    z-index:1000;
    &.container {
      margin-right:auto;
      margin-left:auto;
      @include mq($from: mobile, $until: desktop) {
        margin-top:120px;
        margin-right:auto;
        margin-left:auto;
      }
    }
    .page-header {
      margin: 0;
      text-align: center;
      border: none;
    }
  }

  #fluidcontent {
    width:100%;
    height:100%;
    #block-lt-custom-user-countrymap {
      position:fixed;
      width:100%;
      height:300px;
      z-index:-1001;
      #user_countrymap_canvas {
        width:100%;
        height:300px;
        z-index:-1000;
      }
    }
  }
  #block-lt-custom-uid-triplistfull {
    h2 {
      text-transform: uppercase;
      padding: 0 1em;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      &:before {
        content : "";
        position: absolute;
        left    : 0;
        bottom  : 0;
        height  : 1px;
        width   : 50%;  /* or 100px */
        margin-left: 25%;
        border-bottom:1px solid #AAA;
      }
    }
    a:link, a:visited {
      text-decoration: none;
      color: #000;
      font-weight: bold;
    }
    a:hover {
      color: #707070;
    }
    min-height: 150px;
    padding:5px;
    .tripentry {
      padding-left: 2.1em;
      padding-right: 2.1em;
      margin-bottom: 2.5em;
      padding-bottom: 2.5em;
      position: relative;
      &:before {
        content : "";
        position: absolute;
        left    : 0;
        bottom  : 0;
        height  : 1px;
        width   : 50%;  /* or 100px */
        margin-left: 25%;
        border-bottom:1px solid #AAA;
      }
      .field_entry_header {
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .field_trip_body {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .field_entry_image {
        min-height: 100px;
      }
      .field_countries_list {
        text-align: justify;
        .fieldlabel {
          font-weight: bold;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      .tripentrydataright {
        .contentarea {
          margin-top: 35%;
          transform: translateY(-50%);
          > div {
            line-height: 2em;
          }
        }
      }
    }
  }

  #block-views-lt-user-info-user-info {
    min-height:150px;
    padding:5px;
    .view-header {
      h3 {
        text-transform: uppercase;
      }
    }
    .view-content {
      .views-row {
        margin-bottom:10px;
        .views-field-nothing {
          span > a {
            display:block;
            font-style:italic;
            font-weight:bold;
          }
        }
      }
      a {
        color: black;
        transition: all 0.2s ease 0s;
        &:hover {
          color:#555;
          text-decoration: none;
        }
      }
    }
  }
  #block-views-lt-user-info-user-entries {
    .view-header {
      h3 {
        text-transform: uppercase;
      }
    }
    /*border:1px solid #eee;*/
    min-height:150px;
    padding:5px;
  }
  #block-views-lt-user-info-userposts {
    margin-top:20px;
    padding:10px;
    background: var(--blue-transparency-bg);
  }
  #block-views-lt-user-info-us-postcount,
  #block-views-lt-user-info-us-tripcount,
  #block-views-lt-user-info-us-countrycount {
    padding:5px;
    font-size: 1.7em;
    text-align: center;
    text-transform: uppercase;
    .view-footer {
      font-size:0.5em;
    }
  }
  #block-views-lt-user-info-ue-info {
    margin-top: 10px;
    padding-top: 0;
    padding-bottom: 10px;
    padding-left: 5em;
    padding-right: 5em;
    text-align: center;
    .views-field  {
      padding-bottom: 10px;
    }
    .views-field-field-u-travellername {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
  #block-views-lt-user-info-user-social {
    padding:10px;
    padding-top: 10px;
    padding-bottom: 10px;
    .views-field {
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
  #block-views-lt-user-info-countries-ajax {
    margin-top: 10px;
    margin-bottom:0px;
    padding:0 3em;
    font-size: 0.9em;
    text-align: center;
    .view-id-lt_user_info {
      .view-header {
        display: none;
      }
    }
  }
  #block-lt-custom-uid-social {
    .social_links_content {
      display: flex;
      justify-content: center;
      margin: 1.5em 0;
      .social-field {
        float:left;
        padding: 0 4em;
      }
      a {
        color: #FFF;
      }
    }
  }
}


@media #{$mobile-max} {
.page-users {
  .main-container.container {
    margin-top: 40px;
  }
  .view-lt-user-info .views-field-field-u-avatar img {
    width: 160px;
  }
  aside.content.bottom.left, aside.content.bottom.right {
    height: calc(100vh - 40px);
  }
  aside.content.bottom.left {
    padding: 0;
  }
  #block-views-lt-user-info-countries-ajax {
    .view-id-lt_user_info {
      .view-header {
        display: block;
      }
    }
  }
  #block-lt-custom-uid-triplistfull {
    .tripentry {
      padding-left: 0.5em;
      padding-right: 0.5em;
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      .tripentrydataright {
        .contentarea {
          transform: none;
          margin-top: 10px;
        }
      }
    }
  }
  #block-lt-custom-uid-social {
    .social_links_content {
      .social-field {
        padding: 0 3em;
      }
    }
  }
}

}

@media #{$tablet-normal} {
  .page-users {
    #block-lt-custom-uid-triplistfull {
      .tripentry {
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 1.5em;
        padding-bottom: 1.5em;
        .tripentrydataright {
          .contentarea {
            transform: none;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
