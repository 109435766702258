/* MODS BELOW */

body.not-logged-in {
  #fluidcontainer {
    margin-top:60px;
  }
}

header#navbar {
  border:none;
  background-color:white;
  ul.menu.nav {
    float:right;
  }
  ul.menu li {
    float:left;
    margin: 0 5px;
    text-transform: uppercase;
    a {
      color:#555;
      font-weight: bold;
    }
    &.active {
      a {
        color:#555555;
        &.active {
          background-color:#FFBC00;
        }
      }
    }
  }
  a.logo img {
    margin-bottom:-22px;
  }
}


html.is-screen-xs {
  header#navbar {
    ul.menu {
      li {
        float:none;
        clear:both;
      }
    }
  }
}

/* Advertisement block */

body.front {
  .main-container {
    margin-top:20px;
  }
  #fluidcontainer {
    h1.page-header {
      border:none;
      padding:0px;
      position: absolute;
      top:1vh;
      z-index:450;
      width:70%;
      font-size:6vw;
      left:5%;
      margin:0 auto;
      text-align: left;
      text-transform: uppercase;
      font-weight:bold;
      line-height: 1.3em;
      @include mq($from: mobile, $until: tablet) {
        text-align: center;
        width:100%;
        left:auto;
      }
    }
    .map_overlay {
      content:"";
      display: block;
      height: 600px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 188, 0, 0.55);
      z-index:449;
      @include mq($from: mobile, $until: tablet) {
        display:none;
      }
    }
  }
  #fluidcontainer1 {
    box-shadow: 0px 5px 8px 0px #999999;
    margin-bottom:10px;
    #block-lt-custom-app-right {
      padding:20px;
      li.lt_scr {
        float:left;
        list-style: none;
        padding:0 10px;
        @include mq($from: tablet, $until: wide) {
          padding:4px 5px;
        }
        img.appimg {
          margin-top:0px;
          width:120px;
          padding-bottom:20px;
          @include mq($from: mobile, $until: tablet) {
            width:70px;
          }
        }
      }
    }
    #block-lt-custom-app-left {
      padding:0px;
      .app_imgs {
        img {
          display:block;
          margin:0 auto;
          &.appimg {
            &.lt_logo {
              border-radius:15px;
            }
          }
        }
      }
      .app_text {
        font-size:1.8em;
        padding:5px;
        color:white;
        font-family: 'Kalam', cursive;
        text-align: center;
        margin:10px 0;
        text-shadow: 1px 1px 2px #000000;
        @include mq($from: mobile, $until: tablet) {
          font-size:1.5em;
        }
      }
    }
  }
  #fluidcontainer5 {
    height:400px;
    @include mq($from: mobile, $until: tablet) {
      height:550px;
    }
    @include mq($from: tablet, $until: desktop) {
      height:450px;
    }
    #block-views-lt-front-stats-stats-entries {
      font-family: 'Kalam', cursive;
      color:#FFF;
      margin-top:8vh;
      @include mq($from: tablet, $until: desktop) {
        margin-top:1vh;
      }
      .stat_hdr {
        font-size:3.3em;
        text-align: center;
        padding: 30px 0;
        @include mq($from: mobile, $until: tablet) {
          font-size:2em;
          padding:12px 0;
        }
        @include mq($from: tablet, $until: desktop) {
          padding:20px 0;
        }
      }
      .attachment {
        font-size:1.5em;
        text-align: center;
        .views-field-nothing {
          font-size:1.2em;
        }
        .view-lt-front-stats {
          @include mq($from: tablet, $until: desktop) {
            margin-top:20px;
          }
          @include mq($from: mobile, $until: tablet) {
            margin-top:20px;
          }
        }
        .fa {
          &:before {
            font-size:2em;
          }
          .field-content {
            margin-top:10px;
            font-size:2em;
            @include mq($from: mobile, $until: tablet) {
              font-size:1.2em;
            }
          }
        }
      }
    }
  }
  #fluidcontainer3 {
    height:auto;
  }
  .fp_upper {
    background-color:#EEE;
  }
  .fp_map {
    backround-color:#EEE;
  }
  #fluidcontainer3 {
    min-height:460px;
  }
}

body .fluid.below_content_2 {
  height:450px;
  font-size:3em;
  margin:0 auto;
  line-height: 350px;
}

body {
  .main-container {
    margin-top:10px;
    .container {
      font-size:1.15em;
    }
  }
}

body.front .footer {
  margin-top:0px;
}


/* Admin menu fixes */
body.admin-menu.front {
  #fluidcontainer {
    .map_overlay {
      top:93px;
    }
    h1.page-header {
      top:100px;
    }
  }
}


/* Triplist page - URL : /triplist */
.page-triplist  {
  h1.page-header {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    border: none;
    @include mq($from: mobile, $until: tablet) {
      margin-bottom: 0px;
    }
  }
  .view-lt-list-of-trips {
    a {
      text-decoration: none;
    }
    .views-row-enclosure {
      margin-bottom:30px;
      border:1px solid #DDD;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-bottom-right-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -moz-border-radius-bottomleft: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: #FFF;
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }
    .content {
      .info {
        a {
          color:#555;
        }
      }
    }
    .content_title {
      position:absolute;
      width:auto;
      left:0;
      right:0;
      margin:0 auto;
      text-align: center;
      background-color:rgba(85, 85, 85, 0.7);
      padding: 10px 7px;
      a {
        font-size:1.2em;
        color: white;
        font-weight: bold;
        @include mq($from: mobile, $until: tablet) {
          font-size:1.3em;
        }
      }
    }

    .views-field-nothing {
      position:relative;
    }

    .views-field-body,
    .body {
      min-height:100px;
      clear:both;
      margin-bottom:10px;
      @media #{$tablet-normal} {
        min-height:133px;
      }
      a:link,
      a:visited {
        color:#555;
      }
      /* Set min-height to 608px */
      .field-content {
        margin-top:15px;
        line-height: 1.45;
        position: relative;
        color: #555;
      }
    }
    .views-field-field-u-avatar {
      .field-content {
        a img {
          margin:0 auto;
        }
      }
    }
    .views-field-name,
    .views-field-travellername-link {
      float:left;
      padding: 48px 0px 15px 20px;
      a:link,
      a:visited {
        color:#555;
        font-weight: bold;
      }
    }
    .views-field-title {
      position:absolute;
      width: 100%;
      left:0;
      right:0;
      margin:0 auto;
      text-align: center;
      background-color:rgba(85, 85, 85, 0.7);
      margin-left:16px;
      margin-right:16px;
      padding: 10px 0;
      a {
        font-size:1.2em;
        color: white;
        font-weight: bold;
        @include mq($from: mobile, $until: tablet) {
          font-size:1.3em;
        }
      }
    }

    ul.pager {
      li.pager-previous,
      li.pager-current {
        display: none;
      }
    }
  }
  .view-display-id-triplist_wfilters {
    .views-field-nothing {
      position:relative;
      a {
        transition: all 0.2s ease 0s;
      }
      a.username {
        color:#000;
        font-weight:bold;
        &:hover {
          color:#ffbc00;
          text-decoration:none;
        }
      }
    }

  }

  #views-exposed-form-lt-list-of-trips-triplist-wfilters {
    .views-exposed-form {
      position: relative;
      margin:40px 0;
      padding:5px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      -webkit-box-shadow: rgba(0,0,0,0.9) 0px 0 5px;
      -moz-box-shadow: rgba(0,0,0,0.9) 0 0 5px;
      box-shadow: rgba(0,0,0,0.9) 0 0 5px;
      @include mq($from: mobile, $until: tablet) {
        margin:40px 10px;
        padding:10px;
        background: rgba(255, 255, 255, 0.8);
      }
      .views-exposed-widget {
        float: none;
        width: 50%;
        margin: 0 auto;
        @include mq($from: mobile, $until: tablet) {
          width: 100%;
          padding: 0.5em 0.5em;
        }
        &.views-widget-filter-field_trip_transporation_tid {
          width:25%;
          margin-left: 25%;
          float: left;
          @include mq($from: mobile, $until: tablet) {
            width: 100%;
            margin-left: 0;
            float: none;
          }
        }
        &.views-widget-filter-field_t_date_start_value {
          width: 25%;
          margin-right: 25%;
          margin-right: 0;
          float: left;
          @include mq($from: mobile, $until: tablet) {
            width: 100%;
            float: none;
          }
        }
        &.views-submit-button {
          width: 25%;
          float: left;
          margin-left: 25%;
          .btn {
            margin-left: 25%;
            @media only screen and (max-width: 450px) {
              width: 100%;
              margin-left: 0;
            }
          }
          @media only screen and (max-width: 450px) {
            width: 100%;
            float: none;
            margin-left: 0;
          }
        }
        &.views-reset-button {
          width: 25%;
          float: left;
          margin-right: 25%;
          .btn {
            margin-left: 25%;
            @media only screen and (max-width: 450px) {
              width: 100%;
              margin-left: 0;
            }
          }
          @media only screen and (max-width: 450px) {
            width: 100%;
            float: none;
            margin-left: 0;
          }
        }
      }

    }
  }
}

// Various pages (parallax) - User login

#page-bg.user-login-page {
  background: url('../img/parallax-user-login.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .main-container {
    background-color:#fff;
    border-radius: 25px;
    padding:20px;
    margin-top:240px;
    margin-bottom:100px;
    @include mq($from: mobile, $until: tablet) {
      margin-top:150px;
      margin-bottom:50px;
    }
    @include mq($from: tablet, $until: desktop) {
      margin-top:150px;
      margin-bottom:100px;
    }
  }
}

.page-triplist {
  #content_area {
    background-color: #D5D5D5;
  }
  #fluidcontent {
    margin-top:70px;
    min-height:450px;
    width:100%;
    background: url('../img/parallax-triplist.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include mq($from: mobile, $until: tablet) {
      min-height:600px;
      margin-top:91px;
    }
    @include mq($from: tablet, $until: desktop) {
      min-height:300px;
    }
  }
  .main-container {
    margin-top:40px;
    .view-id-lt_list_of_trips {
      @include mq($from: mobile, $until: tablet) {
        padding: 10px;
      }
    }
    .views-row {
      position: relative;
      margin-bottom:20px;
      border:1px solid #DDD;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-bottom-right-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -moz-border-radius-bottomleft: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: #FFF;
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }
  }
}

.page-help {
  #fluidcontent {
    margin-top:70px;
    height:300px;
    width:100%;
    background: url('../img/parallax-global.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include mq($from: mobile, $until: tablet) {
      height:150px;
      margin-top:91px;
    }
    @include mq($from: tablet, $until: desktop) {
      height:200px;
    }
  }
  .main-container {
    margin-top:40px;
    .views-row {
      margin-bottom:20px;
      border:1px solid #DDD;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomright: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: #FFF;
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;

    }
  }
}

.page-registration {
  #fluidcontent {
    margin-top:70px;
    height:300px;
    width:100%;
    background: url('../img/parallax-global.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include mq($from: mobile, $until: tablet) {
      height:150px;
      margin-top:91px;
    }
    @include mq($from: tablet, $until: desktop) {
      height:200px;
    }
  }
  .main-container {
    margin-top:40px;
    .views-row {
      margin-bottom:20px;
      border:1px solid #DDD;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomright: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: #FFF;
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;

    }
  }
}


// Footer

.footer {
  background-color: #161616;
  border-top: 1px solid #404040;
  border-bottom: 1px solid #404040;
  padding:10px 0 0 0;
  margin-top: 20px;
  #block-menu-menu-footer-menu {
    a:link, a:visited {
      color: #FFF;
    }
    a:hover {
      background-color: #FFBC00;
      color: #555555;
    }
  }
  section.block {
    text-align: center;
    color: white;
    ul li {
      display:inline-block;
      float:none;
    }
    .social {
      margin-top:10px;
      margin-bottom:25px;
      ul {
        margin:0;
        padding:0;
      }
      li {
        list-style-type:none;
        margin:0 7px;
        a {
          display: block;
          border: 1px solid #666;
          -webkit-border-radius: 15%;
          -moz-border-radius: 15%;
          -o-border-radius: 15%;
          -ms-border-radius: 15%;
          border-radius: 15%;
          margin: 0.5em;
          padding: 1.2em;
          text-align: center;
          list-style-type: none;
          transition: all 0.5s ease 0s;
          -moz-transition: all 0.5s ease 0s;
          -webkit-transition: all 0.5s ease 0s;
          -ms-transition: all 0.5s ease 0s;
          -o-transition: all 0.5s ease 0s;
          float: left;
        }
        a:link {
          color: #fcd708;
        }
      }
    }
    .disclaimer {
      background-color: #000;
      border-top:1px solid #404040;
      color: #636262;
      font-size: 0.8em;
      text-align: center;
      font-weight: 700;
      padding: 3px;
    }

  }
}


/* Parallax */

body.front {
  background-color: #EEE;
  #content-parallax1 {
    background: url('../img/parallax_01.jpg');
    width:100%;
    height:600px;
    background-attachment: fixed;
    background-size: cover;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    @include mq($from: mobile, $until: tablet) {
      height:800px;
    }
    @include mq($from: tablet, $until: desktop) {
      height:750px;
    }
    .container {
      margin-top:20px;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
  #content-parallax2 {
    background: url('../img/parallax_03.jpg');
    width:100%;
    height:450px;
    background-attachment: fixed;
    background-size: cover;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    @include mq($from: mobile, $until: tablet) {
      height:300px;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
  #content-parallax3 {
    background: url('../img/parallax_04i.jpg');
    width:100%;
    height:450px;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    text-shadow: 2px 2px 2px #000000;
    @include mq($from: mobile, $until: tablet) {
      height:600px;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
}



/* Page: "About us" */

body.page-node-185 {
  background-color: #EEE;
  #content-parallax1 {
    background: url('../img/abt_parallax00.jpg');
    width:100%;
    height:600px;
    background-attachment: fixed;
    background-size: cover;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    @include mq($from: mobile, $until: tablet) {
      height:800px;
    }
    @include mq($from: tablet, $until: desktop) {
      height:750px;
    }
    .container {
      margin-top:20px;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
  .main-container {
    .parallax {
      margin-top:20px;
      margin-bottom:20px;
      &.last {
        margin-bottom:0px;
      }
    }
    .about-content {
      color: white;
      .about-content-title {
        font-size:24px;
        text-align: center;
        margin-top:24px;
        margin-bottom:10px;
        font-weight:500;
        line-height:1.1;
        color: black;
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
      }
      .about-content-img {
        text-align: center;
        img {
          border-radius: 50%;
          border: 3px solid #fff;
          width: 100px;
          height: 100px;
          transition: all 0.6s ease;
          display: inline-block;
        }
        img:hover {
          -moz-transform: scale(1.5);
          -webkit-transform: scale(1.5);
          transform: scale(1.5);
        }
      }
      .about-content-body {
        text-align: justify;
        color:white;
        margin-top:24px;
        margin-bottom:10px;
      }
    }

  }
  #content-parallax2 {
    background: url('../img/abt_parallax01.jpg');
    background-position: center;
    width:100%;
    height:450px;
    background-attachment: fixed;
    background-size: cover;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    @include mq($from: mobile, $until: tablet) {
      height:300px;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
  #content-parallax3 {
    background: url('../img/abt_parallax02.jpg');
    background-position: center;
    width:100%;
    height:auto;
    background-attachment: fixed;
    background-size: cover;
    /* Show shadow below */
    box-shadow: 0px 5px 8px 0px #999999;
    /* Bordering top and bottom */
    border-top:1px solid black;
    border-bottom:1px solid black;
    @include mq($from: mobile, $until: tablet) {

    }
    h2 {
      text-align: center;
      color: black;
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
    }
    .parallax-content {
      font-size: 10em;
      color: white;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      line-height:350px;
      -webkit-text-stroke: 2px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:5em;
        line-height:200px;
      }
    }
    .parallax-subcontent {
      font-size:1.7em;
      color:white;
      line-height:30px;
      text-align: center;
      -webkit-text-stroke: 1px #666;
      -webkit-text-fill-color: white;
      @include mq($from: mobile, $until: tablet) {
        font-size:1.2em;
        -webkit-text-stroke:inherit;
      }
    }
  }
  .footer {
    margin-top:0px;
  }

}
