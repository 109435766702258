#entry_slider  {
  padding:20px 0 0 0;
  margin-top:36px;
  display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  #slider_prev {
    width:12%;
    text-align: left;
    padding-right:10px;
    @media #{$mobile-max} {
      padding-right:36px;
    }
  }
  #slider_next {
    width:12%;
    text-align: right;
    padding-left:10px;
    @media #{$mobile-max} {
      padding-left:8px;
    }
  }
  #slider_prev,
  #slider_next {
    cursor: pointer;
    font-size:2em;
    margin-top:-16px;
    &:active {
      color:#fcd607;
    }
  }
  #slider_container {
    float: none;
    clear: both;
    height:4px;
    width:90%;
    /*background-image: -webkit-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
    background-image: -moz-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
    background-image: -o-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
    background-image: linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%); */
    &.ui-slider {
      background-image: -webkit-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
      background-image: -moz-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
      background-image: -o-linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
      background-image: linear-gradient(left, rgb(0,128,0) 0%, rgb(255,255,0) 100%);
      border-radius: 25px;
    }
    .ui-state-default {
      background: none;
      background-color: #FFF;
    }
    .ui-slider-handle {
      height:2.2em;
      cursor:pointer;
      position: absolute;
      z-index: 2;
      outline: none;
      top: -12px;
      margin-left: -12px;
    }
    .slider_tooltip {
      position: relative;
      /*background: #88b7d5;*/
      background: #333;
      /*border: 4px solid #c2e1f5;*/
      width:100px;
      height:26px;
      text-align: center;
      top:-38px;
      left:-41px;
      color: #FFF;
      line-height:1.4em;
      font-size:1em;
      @media #{$mobile-max} {
        line-height:1.2em;
        font-size:1em;
        width:90px;
        height:26px;
        top:-38px;
        left:-37px;
        border-radius:12px;
      }
    }
    .slider_tooltip:after, .slider_tooltip:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .slider_tooltip:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #333;
      border-width: 10px;
      margin-left: -12px;
    }
  }
}
